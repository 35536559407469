<template>
    <ODataLookup :data-object="dsDisciplinesLookup" :whereClause="getWhereClause"
    reloadOnWhereClauseChange>
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
            <span v-else :ref="target" style="cursor:pointer;">                
            </span>
        </template>
        <template #toolbarActions>
            <div class="form-check">
                <input class="form-check-input" id="dicLookup_RestrictToContext" type="checkbox" 
                    v-model="restrictedToContext" @click="toggleContext">
                <label class="form-check-label" for="dicLookup_RestrictToContext">{{ $t("Restrict to Context") }}</label>
            </div>
        </template>
        <OColumn field="ID" width="80"></OColumn>
        <OColumn field="NameAndTitle" width="300" :headerName='$t("Name")'></OColumn>
        <!-- <OColumn field="Description" width="300"></OColumn> -->
    </ODataLookup>
</template>

<script setup>
    import { ref, defineProps, computed } from 'vue';
    import { ODataLookup } from 'o365-datalookup';

    const props = defineProps({
        is: String,
        OrgUnitId: Number,
        textInputValue: String,
        textInput: Boolean
    });

    const dsDisciplinesLookup = $getDataObjectById("dsAssets_DisciplinesLookup");

    const restrictedToContext = ref(true);

    const getWhereClause = computed(() => {
        var vFilter = [];
        
        if (props.OrgUnitId && restrictedToContext.value) {
            vFilter.push("PublishedToOrgUnit_ID = " + props.OrgUnitId);
        }

        if (!props.OrgUnitId) {
            vFilter.push("1=2");
        }

        return vFilter.join(" AND ");
    });        

    function toggleContext(e, o) {
        restrictedToContext.value = !restrictedToContext.value;
    }
    
</script>